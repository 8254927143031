import { css } from 'styled-components';

export const Type = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .h1 small,
  .h2 small,
  .h3 small,
  .h4 small,
  .h5 small,
  .h6 small,
  h1 .small,
  h2 .small,
  h3 .small,
  h4 .small,
  h5 .small,
  h6 .small,
  .h1 .small,
  .h2 .small,
  .h3 .small,
  .h4 .small,
  .h5 .small,
  .h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h1 small,
  .h1 small,
  h2 small,
  .h2 small,
  h3 small,
  .h3 small,
  h1 .small,
  .h1 .small,
  h2 .small,
  .h2 .small,
  h3 .small,
  .h3 .small {
    font-size: 65%;
  }
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h4 small,
  .h4 small,
  h5 small,
  .h5 small,
  h6 small,
  .h6 small,
  h4 .small,
  .h4 .small,
  h5 .small,
  .h5 .small,
  h6 .small,
  .h6 .small {
    font-size: 75%;
  }
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h4,
  .h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  h6,
  .h6 {
    font-size: 12px;
  }
  p {
    margin: 0 0 10px;
  }
  .lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }
  @media (min-width: 768px) {
    .lead {
      font-size: 21px;
    }
  }
  small,
  .small {
    font-size: 85%;
  }
  mark,
  .mark {
    padding: .2em;
    background-color: #fcf8e3;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
  .text-nowrap {
    white-space: nowrap;
  }
  .text-lowercase {
    text-transform: lowercase;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .text-muted {
    color: #777;
  }
  .text-primary {
    color: #337ab7;
  }
  a.text-primary:hover,
  a.text-primary:focus {
    color: #286090;
  }
  .text-success {
    color: #3c763d;
  }
  a.text-success:hover,
  a.text-success:focus {
    color: #2b542c;
  }
  .text-info {
    color: #31708f;
  }
  a.text-info:hover,
  a.text-info:focus {
    color: #245269;
  }
  .text-warning {
    color: #8a6d3b;
  }
  a.text-warning:hover,
  a.text-warning:focus {
    color: #66512c;
  }
  .text-danger {
    color: #a94442;
  }
  a.text-danger:hover,
  a.text-danger:focus {
    color: #843534;
  }
`;
