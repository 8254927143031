import { css } from 'styled-components';

export const Lists = css`
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul ul,
  ol ul,
  ul ol,
  ol ol {
    margin-bottom: 0;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
  }
  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
  dl {
    margin-top: 0;
    margin-bottom: 20px;
  }
  dt,
  dd {
    line-height: 1.42857143;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 0;
  }
  @media (min-width: 768px) {
    .dl-horizontal dt {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .dl-horizontal dd {
      margin-left: 180px;
    }
  }
`;
