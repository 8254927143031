import { css } from 'styled-components';

export const Transitions = css`
  .fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
            transition: opacity .15s linear;
  }
  .fade.in {
    opacity: 1;
  }
  .collapse {
    display: none;
  }
  .collapse.in {
    display: block;
  }
  tr.collapse.in {
    display: table-row;
  }
  tbody.collapse.in {
    display: table-row-group;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
        -o-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .35s;
        -o-transition-duration: .35s;
            transition-duration: .35s;
    -webkit-transition-property: height, visibility;
        -o-transition-property: height, visibility;
            transition-property: height, visibility;
  }
`;
