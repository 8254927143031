import { css } from 'styled-components';

export const Pages = css`
  .filters-container {
    margin: 0 auto;
    padding: 20px 0;
    width: 200px;
  }

  /* Reports*/

  .category-toogle {
    color: #000;
    cursor: pointer;
  }

  .category-toogle:hover {
    text-decoration: none;
  }

  .category-column {
    width: 50%;
  }

  .percentage-column {
    width: 25%
  }

  .value-column {
    width: 25%;
  }

  .without-margin {
    margin: 0;
  }

  /* Settings */
  .name-category {
    width: 85%;
  }

  /* Home */
  .img-background {
    background-image: url("/assets/home_background.jpg");
    height: 920px;
    width: 100%;
  }

  .home .top-bar {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }

  .home .site-logo {
    display: block;
    font-size: 16px;
    padding: 20px;
  }

  .home .top-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .home .top-nav li {
    display: inline-block;
  }

  .home .top-nav a {
    display: inline-block;
    font-size: 16px;
    padding: 20px;
  }

  .home .signup-btn {
    background-color: green;
    color: #fff;
    display: block;
    padding: 16px;
    text-align: center;
  }

  .home .signup-btn:hover {
    background-color: #039d03;
    text-decoration: none;
  }

  .section.intro .intro-block {
    display: block;
    padding-top: 150px;
    margin: 0 auto;
    width: 300px;
  }

  .intro-block .signup-btn {
    margin-top: 10px;
  }

  .section.intro span {
    color: #fff;
    font-size: 24px;
  }

  .cloak {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }
`;
