import { apiFetch } from './common';
import DateService from './date';

export default class TransactionsService {
  static fetchAll(startDate = '', endDate = '') {
    return apiFetch(`/transactions/${startDate}/${endDate}`, 'GET')
      .then((responseContent) => {
        const response: ITransaction[] = [...responseContent];
        return response;
      });
  }

  static fetch(id: number) {
    return apiFetch(`/transactions/${id}`, 'GET')
      .then((responseContent) => {
        const response: ITransaction = { ...responseContent };
        return response;
      });
  }

  static delete(transactionId: string) {
    return apiFetch(`/transactions/${transactionId}`, 'DELETE');
  }

  static create(transaction: ITransaction) {
    return apiFetch('/transactions/', 'POST', transaction)
      .then((responseContent: any) => {
        const response: ITransaction = { ...responseContent };
        return response;
      });
  }

  static update(transaction: ITransaction) {
    return apiFetch(`/transactions/${transaction.id}`, 'PUT', transaction)
      .then((responseContent: any) => {
        const response: ITransaction = { ...responseContent };
        return response;
      });
  }

  static save(transaction: ITransaction) {
    transaction.date = DateService.getApiSanitizedDate(transaction.date);

    if (transaction.id) {
      return this.update(transaction);
    }

    return this.create(transaction);
  }
}

export interface ITransaction {
  id: string;
  date: string;
  description: string;
  type: string;
  value: number;
  category: string;
}
