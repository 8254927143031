import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray.black};
    color: ${theme.colors.primary[200]};
    height: 100%;
    padding: 50px 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 200px;
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary[200]};
    font-size: ${theme.font.sizes.xxlarge};
    margin-bottom: ${theme.spacings.xsmall};
    text-align: center;
  `}
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  ${({ theme, isActive }) => css`
    display: block;
    font-weight: ${theme.font.light};
    margin-bottom: ${theme.spacings.large};

    a {
      color: ${theme.colors.primary[200]};
      display: block;
      text-transform: uppercase;

      &:hover {
        color: ${theme.colors.primary[100]};

        svg {
          color: ${theme.colors.primary[100]};
        }
      }
    }

    ${isActive
      && css`
        a {
          color: ${theme.colors.primary[100]};
        }

        svg {
          color: ${theme.colors.primary[100]};
        }
      `}
  `}
`;
