import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import CategoriesService, { ICategory } from '../../../services/categories';

interface ICategoryListItem {
  category: ICategory;
  onDelete(): any;
}

const CategoryListItem: React.FC<ICategoryListItem> = ({ category, onDelete }) => {
  const { addConfirmToast } = useToast();

  const handleDelete = () => {
    addConfirmToast({
      title: 'Remove category',
      type: 'info',
      description: 'Esta ação é irreversível. Deseja continuar?',
      confirmCallback: () => {
        CategoriesService.delete(category.id)
          .then(() => {
            onDelete();
          });
      },
    });
  };

  return (
    <tr>
      <td className="name-category">
        <Link to={`/categories/${category.id}/edit`}>
          {
            category.name ? category.name : category.id
          }
        </Link>
      </td>
      <td>
        <button className="btn btn-danger mr-3" type="button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

export default CategoryListItem;
