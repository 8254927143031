import React, { AnchorHTMLAttributes } from 'react';

import { Container } from './styles';

const LinkButton: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, ...rest }) => (
  <Container {...rest}>
    {children}
  </Container>
);

export default LinkButton;
