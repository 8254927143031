import React from 'react';
import { Link } from 'react-router-dom';

import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, IconContainer, MenuItem } from './styles';

const Sidebar: React.FC = () => {
  const menuItems = [
    {
      icon: faTh,
      isActive: true,
      title: 'Overview',
      to: '/transactions',
    },
    {
      icon: faList,
      isActive: false,
      title: 'Profile',
      to: '/settings',
    },
    {
      icon: faList,
      isActive: false,
      title: 'Report',
      to: '/reports',
    },
    {
      icon: faList,
      isActive: false,
      title: 'Importer',
      to: '/importer',
    },
    {
      icon: faList,
      isActive: false,
      title: 'Exporter',
      to: '/exporter',
    },
  ];

  return (
    <Container>
      <div className="side-nav-content">
        <ul className="menu">
          {
            menuItems.map((menuItem) => (
              <MenuItem isActive={menuItem.isActive}>
                <Link
                  to={menuItem.to}
                  className="menu-item active"
                >
                  <IconContainer>
                    <FontAwesomeIcon icon={menuItem.icon} />
                  </IconContainer>
                  {menuItem.title}
                </Link>
              </MenuItem>
            ))
          }
        </ul>
      </div>
    </Container>
  );
};

export default Sidebar;
