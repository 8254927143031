import React from 'react';
import DataService from '../../services/data';
import InternalTemplate from '../../templates/Internal';

const Exporter: React.FC = () => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    DataService.export()
      .then((response: any) => {
        const link = document.createElement('a');
        link.href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(response))}`;
        link.download = 'backup.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <InternalTemplate>
      <h1>Exportador</h1>
      <form onSubmit={handleSubmit}>
        <button className="btn btn-success" type="submit">Exportar</button>
      </form>
    </InternalTemplate>
  );
};

export default Exporter;
