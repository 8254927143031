import { apiFetch } from './common';

export default class CategoriesService {
  static fetchAll() {
    return apiFetch('/categories', 'GET')
      .then((responseContent) => {
        const response: ICategory[] = [...responseContent];
        return response;
      });
  }

  static fetch(id: number) {
    return apiFetch(`/categories/${id}`, 'GET')
      .then((responseContent) => {
        const response: ICategory = { ...responseContent };
        return response;
      });
  }

  static delete(categoryId: string) {
    return apiFetch(`/categories/${categoryId}`, 'DELETE');
  }

  static create(category: ICategory) {
    return apiFetch('/categories/', 'POST', category)
      .then((responseContent: any) => {
        const response: ICategory = { ...responseContent };
        return response;
      });
  }

  static update(category: ICategory) {
    return apiFetch(`/categories/${category.id}`, 'PUT', category)
      .then((responseContent: any) => {
        const response: ICategory = { ...responseContent };
        return response;
      });
  }

  static save(category: ICategory) {
    if (category.id) {
      return this.update(category);
    }

    return this.create(category);
  }
}

export interface ICategory {
  id: string;
  icon: string;
  name: string;
}
