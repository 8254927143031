import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[300]};
    color: ${theme.colors.primary[200]};
    display: flex;
    flex-direction: row;
    margin-bottom: ${theme.spacings.medium};
    padding: ${theme.spacings.medium} ${theme.spacings.large};
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-orientation: row;
    padding-right: ${theme.spacings.xxlarge};
    width: 35rem;

    svg {
      color: #2abdea;
      font-size: ${theme.font.sizes.xlarge};
    }

    span {
      color: ${theme.colors.white};
      margin-left: ${theme.spacings.xxsmall};
    }
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.spacings.xxlarge};
    width: 65rem;

    span {
      color: ${theme.colors.white};
    }
  `}
`;

export const ValueContainer = styled.div`
  ${({ theme }) => css`
    padding-right: ${theme.spacings.xxlarge};
    width: 20rem;

    span {
      color: ${theme.colors.white};
    }
  `}
`;

export const ExpenseValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red} !important;
  `}
`;

export const RevenueValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green} !important;
  `}
`;
