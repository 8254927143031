import styled, { css } from 'styled-components';

export const Table = styled.table`
  ${({ theme }) => css`
    .category-column {
      a {
        font-size: ${theme.font.sizes.xsmall};
      }
    }

    a {
      color: ${theme.colors.gray[100]};
    }
  `}
`;

export const AccordionHead = styled.div`
  display: flex;
  justify-content: space-between;
`;
