export default {
  colors: {
    gray: {
      100: '#d2d2d2',
      200: '#b2b2b2',
      300: '#1c1c1c',
      400: '#131313',
      500: '#121212',
      600: '#141212',
      700: '#2c2a2b',
      black: '#000000',
    },
    white: '#ffffff',
    primary: {
      100: '#3de5c9',
      200: '#077e68',
    },
    red: 'red',
    green: 'green',
  },
  font: {
    family:
      "Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
      huge: '5.2rem',
    },
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
};
