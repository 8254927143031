import { css } from 'styled-components';

export const Labels = css`
  .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  }
  a.label:hover,
  a.label:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .label:empty {
    display: none;
  }
  .btn .label {
    position: relative;
    top: -1px;
  }
  .label-default {
    background-color: #777;
  }
  .label-default[href]:hover,
  .label-default[href]:focus {
    background-color: #5e5e5e;
  }
  .label-primary {
    background-color: #337ab7;
  }
  .label-primary[href]:hover,
  .label-primary[href]:focus {
    background-color: #286090;
  }
  .label-success {
    background-color: #5cb85c;
  }
  .label-success[href]:hover,
  .label-success[href]:focus {
    background-color: #449d44;
  }
  .label-info {
    background-color: #5bc0de;
  }
  .label-info[href]:hover,
  .label-info[href]:focus {
    background-color: #31b0d5;
  }
  .label-warning {
    background-color: #f0ad4e;
  }
  .label-warning[href]:hover,
  .label-warning[href]:focus {
    background-color: #ec971f;
  }
  .label-danger {
    background-color: #d9534f;
  }
  .label-danger[href]:hover,
  .label-danger[href]:focus {
    background-color: #c9302c;
  }
`;
