import styled, { css } from 'styled-components';

export const ImportBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[300]};
    color: ${theme.colors.gray[100]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${theme.spacings.large};
    padding: ${theme.spacings.medium} ${theme.spacings.large};
    max-width: 700px;
    width: 100%;
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[200]};
  `}
`;

export const Value = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[100]};
    font-size: ${theme.spacings.medium};
    margin-bottom: ${theme.spacings.small};
    margin-top: ${theme.spacings.medium};
  `}
`;

export const Controls = styled.div`
  display: flex;
`;
