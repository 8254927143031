import React, { ChangeEventHandler } from 'react';

export interface OptionProps {
  label: string;
  value: string;
}

interface SelectProps {
  options: Array<OptionProps>;
  name: string;
  label: string;
  onChange: ChangeEventHandler<HTMLElement>;
  showSelectOption: boolean;
}

const Select: React.FC<SelectProps> = ({
  label, name, options, onChange, showSelectOption,
}) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <select className="form-control" name={name} onChange={onChange}>
      {
        showSelectOption
        && <option value="">Select</option>
      }
      {
        options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))
      }
    </select>
  </div>
);

export default Select;
