import styled, { css } from 'styled-components';

export const Container = styled.a`
  ${({ theme }) => css`
    background: ${theme.colors.primary[100]};
    border: 0;
    border-radius: 25px;
    color: #fff;
    font-weight: 500;
    height: 40px;
    margin-top: 10px;
    padding: 0 16px;
    transition: background-color 0.2s;
    width: 100%;
  `}
`;
