import React from 'react';

import { IAccordionSummaryProps } from '../AccordionSummary';

const AccordionDetails: React.FC<IAccordionSummaryProps> = ({ children }) => (
  <div>
    {children}
  </div>
);

export default AccordionDetails;
