import Joi from 'joi';
import React, { ChangeEvent, useState } from 'react';
import Input from '../../../components/Input';
import InternalTemplate from '../../../templates/Internal';
import { validate } from '../../../utils/validation';

interface IFormData {
  name: string;
}

const PeopleForm:React.FC = () => {
  const [formData, setFormData] = useState<IFormData>({
    name: '',
  });
  const [fieldErrors, setFieldErrors] = useState([]);

  const fieldsValidations = {
    name: Joi.string().required(),
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const errors = validate(fieldsValidations, formData);

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newState = { ...formData };

    setFormData((s) => ({ ...s, [event.target.name]: event.target.value }));

    setFormData(newState);
  };

  return (
    <InternalTemplate>
      <h1>Pessoa</h1>

      {
          fieldErrors && !!Object.entries(fieldErrors).length
          && (
            <div id="error_explanation">
              <h2>
                {Object.entries(fieldErrors).length}
                {' '}
                error(s) prohibited this transaction from being saved:
              </h2>
              <ul>
                {
                  Object.entries(fieldErrors).map((error, index) => (
                    <li>
                      <span>{Object.keys(fieldErrors)[index]}</span>
                      <span>-</span>
                      <span>{Object.values(fieldErrors)[index]}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }

      <form className="edit_person" onSubmit={handleSubmit}>
        <Input
          label="Name"
          name="date"
          value={formData.name}
          onChange={handleFieldChange}
        />

        <div className="actions">
          <button type="submit" className="btn btn-primary btn-block">Salvar</button>
        </div>
      </form>
    </InternalTemplate>
  );
};

export default PeopleForm;
