export const apiFetch = (url: string, method: string, data?: Object) => {
  const token = localStorage.getItem('@Investify:token');

  const body = JSON.stringify(data) || null;

  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method,
    headers: {
      authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: <string>body,
  })
    .then((response) => {
      if (response.status === 401) {
        // AuthStore.removeToken();
        window.location.replace('/login');
      }

      if (response.status !== 200) {
        throw new Error(`${response.statusText};${response.json()}`);
      }

      const responseContentType = response.headers.get('content-type');
      if (responseContentType && responseContentType.indexOf('application/json') !== -1) {
        return response.json();
      }
      return response.text();
    });
};
