import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';

import Input from '../Input';
import { useToast } from '../../hooks/toast';
import AuthService from '../../services/auth';
import Button from '../Button';
import { FieldError, validate } from '../../utils/validation';
import { useAuth } from '../../hooks/Auth';

interface IFormData {
  email: string;
  password: string;
  password_confirmation: string;
}

const FormSignUp = () => {
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    password: '',
    password_confirmation: '',
  });
  const [fieldErrors, setFieldErrors] = useState<FieldError>({});

  const fieldsValidations = {
    email: Joi.string().required(),
    password: Joi.string().required(),
    password_confirmation: Joi.string().required(),
  };

  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const errors = validate(fieldsValidations, formData);

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    try {
      AuthService.create({
        email: formData.email,
        password: formData.password,
      })
        .then(() => {
          signIn({
            email: formData.email,
            password: formData.password,
          })
            .then(() => {
              history.push('/transactions');
            });
        })
        .catch((err: any) => {
          err.body.then((body: any) => {
            body.errors.forEach((error: any) => {
              addToast({
                type: 'error',
                title: body.message,
                description: error.description,
              });
            });
          });
        });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Something go wrong. Try again later',
        description:
          'Something go wrong. Try again later.',
      });
    }
  };

  const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData((s) => ({ ...s, [event.target.name]: event.target.value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Email"
        placeholder="Email"
        name="email"
        onChange={handleFieldChange}
        value={formData.email}
        error={fieldErrors.email}
      />

      <Input
        label="Password"
        placeholder="Password"
        name="password"
        type="password"
        onChange={handleFieldChange}
        value={formData.password}
        error={fieldErrors.password}
      />

      <Input
        label="Password confirmation"
        placeholder="Password confirmation"
        name="password_confirmation"
        type="password"
        onChange={handleFieldChange}
        value={formData.password_confirmation}
        error={fieldErrors.password_confirmation}
      />

      <Button>Sign up</Button>
    </form>
  );
};

export default FormSignUp;
