import { css } from 'styled-components';

export const Forms = css`
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
  }
  input[type="search"] {
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px;
    line-height: normal;
  }
  input[type="file"] {
    display: block;
  }
  input[type="range"] {
    display: block;
    width: 100%;
  }
  select[multiple],
  select[size] {
    height: auto;
  }
  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
  }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  .form-control:-ms-input-placeholder {
    color: #999;
  }
  .form-control::-webkit-input-placeholder {
    color: #999;
  }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1;
  }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed;
  }
  textarea.form-control {
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: none;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control,
    input[type="time"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control {
      line-height: 34px;
    }
    input[type="date"].input-sm,
    input[type="time"].input-sm,
    input[type="datetime-local"].input-sm,
    input[type="month"].input-sm,
    .input-group-sm input[type="date"],
    .input-group-sm input[type="time"],
    .input-group-sm input[type="datetime-local"],
    .input-group-sm input[type="month"] {
      line-height: 30px;
    }
    input[type="date"].input-lg,
    input[type="time"].input-lg,
    input[type="datetime-local"].input-lg,
    input[type="month"].input-lg,
    .input-group-lg input[type="date"],
    .input-group-lg input[type="time"],
    .input-group-lg input[type="datetime-local"],
    .input-group-lg input[type="month"] {
      line-height: 46px;
    }
  }
  .form-group {
    margin-bottom: 15px;
  }
  .radio,
  .checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .radio input[type="radio"],
  .radio-inline input[type="radio"],
  .checkbox input[type="checkbox"],
  .checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-top: 4px;
    margin-left: -20px;
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: -5px;
  }
  .radio-inline,
  .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
  }
  .radio-inline + .radio-inline,
  .checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
  }
  input[type="radio"][disabled],
  input[type="checkbox"][disabled],
  input[type="radio"].disabled,
  input[type="checkbox"].disabled,
  fieldset[disabled] input[type="radio"],
  fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
  }
  .radio-inline.disabled,
  .checkbox-inline.disabled,
  fieldset[disabled] .radio-inline,
  fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
  }
  .radio.disabled label,
  .checkbox.disabled label,
  fieldset[disabled] .radio label,
  fieldset[disabled] .checkbox label {
    cursor: not-allowed;
  }
  .form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
  }
  .form-control-static.input-lg,
  .form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0;
  }
  .input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  select.input-sm {
    height: 30px;
    line-height: 30px;
  }
  textarea.input-sm,
  select[multiple].input-sm {
    height: auto;
  }
  .form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
  }
  .form-group-sm textarea.form-control,
  .form-group-sm select[multiple].form-control {
    height: auto;
  }
  .form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  select.input-lg {
    height: 46px;
    line-height: 46px;
  }
  textarea.input-lg,
  select[multiple].input-lg {
    height: auto;
  }
  .form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }
  .form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
  }
  .form-group-lg textarea.form-control,
  .form-group-lg select[multiple].form-control {
    height: auto;
  }
  .form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .has-feedback {
    position: relative;
  }
  .has-feedback .form-control {
    padding-right: 42.5px;
  }
  .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
  }
  .input-lg + .form-control-feedback,
  .input-group-lg + .form-control-feedback,
  .form-group-lg .form-control + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
  .input-sm + .form-control-feedback,
  .input-group-sm + .form-control-feedback,
  .form-group-sm .form-control + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
`;
