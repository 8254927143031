import Joi from 'joi';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '../../../components/Input';
import CategoriesService, { ICategory } from '../../../services/categories';
import InternalTemplate from '../../../templates/Internal';
import { validate } from '../../../utils/validation';

const CategoriesFormPage: React.FC = (props: any) => {
  const [pageTitle, setPageTitle] = useState('New Category');
  const [formData, setFormData] = useState<ICategory>({
    id: '',
    name: '',
    icon: '',
  });

  useEffect(() => {
    const { id } = props.match.params;

    if (!id) return;

    setPageTitle('Edit Category');

    CategoriesService.fetch(id)
      .then((category) => {
        setFormData(category);
      });
  }, [props]);

  const history = useHistory();

  const [fieldErrors, setFieldErrors] = useState([]);

  const fieldsValidations = {
    name: Joi.string().required(),
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const errors = validate(fieldsValidations, formData);

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    CategoriesService.save(formData)
      .then(() => {
        history.push('/settings');
      });
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((s) => ({ ...s, [event.target.name]: event.target.value }));
  };

  return (
    <InternalTemplate>
      <h1>{pageTitle}</h1>

      {
        fieldErrors && !!Object.entries(fieldErrors).length
        && (
          <div id="error_explanation">
            <h2>
              {Object.entries(fieldErrors).length}
              {' '}
              error(s) prohibited this transaction from being saved:
            </h2>
            <ul>
              {
                Object.entries(fieldErrors).map((error, index) => (
                  <li>
                    <span>{Object.keys(fieldErrors)[index]}</span>
                    <span>-</span>
                    <span>{Object.values(fieldErrors)[index]}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }

      <form onSubmit={handleSubmit}>
        <Input
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleFieldChange}
        />

        <Input
          label="Icon"
          name="icon"
          value={formData.icon}
          onChange={handleFieldChange}
        />

        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block">Save</button>
        </div>
      </form>
    </InternalTemplate>
  );
};

export default CategoriesFormPage;
