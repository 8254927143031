import { createGlobalStyle, css } from 'styled-components';
import { Application } from './application';
import { Backgrounds } from './backgrounds';
import { Buttons } from './buttons';
import { Dropdowns } from './dropdowns';
import { Forms } from './forms';
import { Images } from './images';
import { Labels } from './labels';
import { Lists } from './lists';
import { Navbar } from './navbar';
import { Other } from './other';
import { Pages } from './pages';
import { Reset } from './reset';
import { Screenreader } from './screenreader';
import { Tables } from './tables';
import { Transitions } from './transitions';
import { Type } from './type';
import { Utils } from './utils';
import { Charts } from './charts';

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    body {
      background-color: ${theme.colors.gray[400]};
      color: #FFFFFFD9;
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.medium};
      -webkit-font-smoothing: antialiased;
    }
  `}

  ${Reset}
  ${Images}
  ${Screenreader}
  ${Type}
  ${Backgrounds}
  ${Lists}
  ${Tables}
  ${Buttons}
  ${Transitions}
  ${Dropdowns}
  ${Navbar}
  ${Labels}
  ${Other}
  ${Utils}
  ${Pages}
  ${Application}
  ${Forms}
  ${Charts}
`;

export default GlobalStyles;
