import React, {
  createContext, useCallback, useContext, useState,
} from 'react';
import { v4 } from 'uuid';

import ToastContainer from '../components/ToastContainer';
import { ToastMessage } from '../interfaces/IToastMessage';

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  addConfirmToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({
      type, title, description, autoHide, confirmCallback,
    }: Omit<ToastMessage, 'id'>) => {
      const id = v4();

      const toast = {
        id,
        type,
        title,
        description,
        autoHide,
        confirmCallback,
      };

      setMessages((oldMessages) => [...oldMessages, toast]);
    },
    [],
  );

  const addConfirmToast = useCallback(
    ({
      type, title, description, confirmCallback,
    }: Omit<ToastMessage, 'id'>) => {
      addToast({
        type,
        title,
        description,
        autoHide: false,
        confirmCallback,
      });
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, addConfirmToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
