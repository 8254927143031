import React from 'react';
import Sidebar from '../../components/Sidebar';
import BaseTemplate from '../Base';

const InternalTemplate: React.FC = ({ children }) => (
  <BaseTemplate>
    <Sidebar />

    <div className="content container">
      {children}
    </div>
  </BaseTemplate>
);

export default InternalTemplate;
