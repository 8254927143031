import { css } from 'styled-components';

export const Application = css`
  .side-nav {
    position: fixed;
    top: 0;
    left: 0;
  }

  .side-nav-btn {
    background-color: #423a3a;
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 50px;
    z-index: 2;
  }

  .toogle-nav-btn {
    color: #ccc;
    cursor: pointer;
  }

  .toogle-nav-btn:hover {
    color: #fff;
  }

  .menu-btn i {
    font-size: 1.5em;
    padding: 15px;
  }

    .side-nav-content .menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .content {
    margin-left: 250px;
  }

  .tcenter {
    display: block;
    text-align: center;
  }
`;
