import { css } from 'styled-components';
import theme from './theme';

export const Utils = css`
  .clearfix:before,
  .clearfix:after,
  .dl-horizontal dd:before,
  .dl-horizontal dd:after,
  .container:before,
  .container:after,
  .container-fluid:before,
  .container-fluid:after,
  .row:before,
  .row:after,
  .form-horizontal .form-group:before,
  .form-horizontal .form-group:after,
  .btn-toolbar:before,
  .btn-toolbar:after,
  .btn-group-vertical > .btn-group:before,
  .btn-group-vertical > .btn-group:after,
  .nav:before,
  .nav:after,
  .navbar:before,
  .navbar:after,
  .navbar-header:before,
  .navbar-header:after,
  .navbar-collapse:before,
  .navbar-collapse:after,
  .pager:before,
  .pager:after,
  .panel-body:before,
  .panel-body:after,
  .modal-header:before,
  .modal-header:after,
  .modal-footer:before,
  .modal-footer:after {
    display: table;
    content: " ";
  }
  .clearfix:after,
  .dl-horizontal dd:after,
  .container:after,
  .container-fluid:after,
  .row:after,
  .form-horizontal .form-group:after,
  .btn-toolbar:after,
  .btn-group-vertical > .btn-group:after,
  .nav:after,
  .navbar:after,
  .navbar-header:after,
  .navbar-collapse:after,
  .pager:after,
  .panel-body:after,
  .modal-header:after,
  .modal-footer:after {
    clear: both;
  }
  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .invisible {
    visibility: hidden;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hidden {
    display: none !important;
  }
  .affix {
    position: fixed;
  }
  @-ms-viewport {
    width: device-width;
  }
  .visible-xs,
  .visible-sm,
  .visible-md,
  .visible-lg {
    display: none !important;
  }
  .visible-xs-block,
  .visible-xs-inline,
  .visible-xs-inline-block,
  .visible-sm-block,
  .visible-sm-inline,
  .visible-sm-inline-block,
  .visible-md-block,
  .visible-md-inline,
  .visible-md-inline-block,
  .visible-lg-block,
  .visible-lg-inline,
  .visible-lg-inline-block {
    display: none !important;
  }
  @media (max-width: 767px) {
    .visible-xs {
      display: block !important;
    }
    table.visible-xs {
      display: table !important;
    }
    tr.visible-xs {
      display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
      display: table-cell !important;
    }
  }
  @media (max-width: 767px) {
    .visible-xs-block {
      display: block !important;
    }
  }
  @media (max-width: 767px) {
    .visible-xs-inline {
      display: inline !important;
    }
  }
  @media (max-width: 767px) {
    .visible-xs-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
      display: block !important;
    }
    table.visible-sm {
      display: table !important;
    }
    tr.visible-sm {
      display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
      display: table-cell !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
      display: block !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
      display: inline !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
      display: block !important;
    }
    table.visible-md {
      display: table !important;
    }
    tr.visible-md {
      display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
      display: table-cell !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
      display: block !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
      display: inline !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 1200px) {
    .visible-lg {
      display: block !important;
    }
    table.visible-lg {
      display: table !important;
    }
    tr.visible-lg {
      display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
      display: table-cell !important;
    }
  }
  @media (min-width: 1200px) {
    .visible-lg-block {
      display: block !important;
    }
  }
  @media (min-width: 1200px) {
    .visible-lg-inline {
      display: inline !important;
    }
  }
  @media (min-width: 1200px) {
    .visible-lg-inline-block {
      display: inline-block !important;
    }
  }
  @media (max-width: 767px) {
    .hidden-xs {
      display: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
      display: none !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
      display: none !important;
    }
  }
  @media (min-width: 1200px) {
    .hidden-lg {
      display: none !important;
    }
  }
  .visible-print {
    display: none !important;
  }
  @media print {
    .visible-print {
      display: block !important;
    }
    table.visible-print {
      display: table !important;
    }
    tr.visible-print {
      display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
      display: table-cell !important;
    }
  }
  .visible-print-block {
    display: none !important;
  }
  @media print {
    .visible-print-block {
      display: block !important;
    }
  }
  .visible-print-inline {
    display: none !important;
  }
  @media print {
    .visible-print-inline {
      display: inline !important;
    }
  }
  .visible-print-inline-block {
    display: none !important;
  }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important;
    }
  }
  @media print {
    .hidden-print {
      display: none !important;
    }
  }

  .gutter-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-20 > [class^="col-"], .gutter-20 > [class^=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .gutter-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-10 > [class^="col-"], .gutter-10 > [class^=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }

  .gutter-0.row {
    margin-right: 0;
    margin-left: 0;
  }
  .gutter-0 > [class^="col-"], .gutter-0 > [class^=" col-"]{
    padding-right: 0;
    padding-left: 0;
  }

  .mr-3 {
    margin-right: ${theme.spacings.xsmall};
  }
`;
