import React from 'react';
import FormLogin from '../../components/FormLogin';

import BaseTemplate from '../../templates/Base';

import {
  Background, Container, Content, FormLink,
} from './styles';

const LoginPage:React.FC = () => (
  <BaseTemplate>
    <Container>
      <Background />
      <Content>
        <div>
          <h2>Sign in</h2>

          <FormLink>
            <span>Dont have an account?</span>
            <a href="/sign_up">Sign up</a>
          </FormLink>

          <FormLogin />
        </div>
      </Content>
    </Container>
  </BaseTemplate>
);

export default LoginPage;
