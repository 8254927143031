import React, { useState, useEffect } from 'react';
import {
  faMoneyBill, faPencilAlt, faTrash, IconName,
} from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/fontawesome-free-solid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import { Link } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import { ICategory } from '../../services/categories';
import TransactionsService, { ITransaction } from '../../services/transactions';
import {
  Container, DescriptionContainer, ExpenseValue, IconContainer, RevenueValue, ValueContainer,
} from './styles';

fontawesome.library.add(faDollarSign);

interface ITransactionListItem {
  categories: ICategory[];
  transaction: ITransaction;
  onDelete(): any;
}

const TransactionListItem: React.FC<ITransactionListItem> = ({
  transaction,
  onDelete,
  categories,
}) => {
  const [category, setCategory] = useState<ICategory>();
  const { addConfirmToast } = useToast();

  const handleDelete = () => {
    addConfirmToast({
      title: 'Remove transaction',
      type: 'info',
      description: 'Esta ação é irreversível. Deseja continuar?',
      confirmCallback: () => {
        TransactionsService.delete(transaction.id)
          .then(() => {
            onDelete();
          });
      },
    });
  };

  useEffect(() => {
    const index = categories.findIndex((categoryItem) => categoryItem.id === transaction.category);

    setCategory(categories[index]);
  }, [categories]);

  return (
    <Container>
      <IconContainer>
        <span>
          {
            transaction.category
              ? (
                <div>
                  {
                    category?.icon
                      ? <FontAwesomeIcon icon={category?.icon as IconName} />
                      : <FontAwesomeIcon icon={faMoneyBill} />
                  }

                  <span>{category?.name}</span>
                </div>
              )
              : (
                <span>Sem categoria</span>
              )
          }
        </span>
      </IconContainer>

      <DescriptionContainer>
        <span>{transaction.description}</span>
      </DescriptionContainer>

      <ValueContainer>
        {
          transaction.type === 'expense'
            ? (
              <ExpenseValue>{transaction.value}</ExpenseValue>
            )
            : (
              <RevenueValue>{transaction.value}</RevenueValue>
            )
        }
      </ValueContainer>

      <ValueContainer>
        <Link className="btn btn-primary mr-3" to={`/transactions/${transaction.id}/edit`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Link>

        <button className="btn btn-danger mr-3" type="button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </ValueContainer>
    </Container>
  );
};

export default TransactionListItem;
