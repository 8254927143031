import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import SignInBackgroundImg from '../../assets/home_background.png';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  height: 100vh;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.gray[300]};
    color: ${theme.colors.gray[100]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 700px;
    width: 100%;

    h2 {
      text-align: center;
    }

    form {
      width: 440px;
    }
  `}
`;

export const Background = styled.div`
  background: url(${SignInBackgroundImg}) no-repeat center;
  background-size: cover;
  flex: 1;
`;

export const ForgotPasswordLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[200]};
    font-size: ${theme.font.sizes.xsmall};
    text-decoration: none;
  `}
`;

export const FormLink = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[300]};
    font-size: ${theme.font.sizes.small};
    margin-bottom: ${theme.spacings.medium};
    margin-top: ${theme.spacings.medium};
    text-align: center;

    span {
      color: ${theme.colors.gray[200]};
      margin-right: ${theme.spacings.xxsmall};
      text-align: center;
    }

    a {
      color: ${theme.colors.gray[200]};
      font-weight: ${theme.font.bold};

      &:hover {
        color: ${theme.colors.gray[100]};
        text-decoration: none;
      }
    }
  `}
`;
