import styled, { css } from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray[400]};
    border-radius: ${theme.spacings.medium};
    padding:
      ${theme.spacings.medium}
      ${theme.spacings.medium}
      ${theme.spacings.medium}
      ${theme.spacings.medium};
    width: 100%;
  `};
`;
