import React from 'react';
import Button from '../Button';
import Select, { OptionProps } from '../Select';
import { useToast } from '../../hooks/toast';
import TransactionsService from '../../services/transactions';

import {
  Controls, Description, ImportBox, Value,
} from './styles';

interface ITransaction {
  category?: string;
  date: string;
  description: string;
  id?: string;
  type: string;
  value: number;
}

export interface IConcilatedTransaction {
  original: ITransaction;
  found?: any;
}

interface IImporterTransactionListProps {
  categories: OptionProps[];
  transactions: IConcilatedTransaction[];
  onChange: any;
}

const ImporterTransactionList: React.FC<IImporterTransactionListProps> = ({
  categories, transactions, onChange,
}) => {
  const { addToast } = useToast();

  const importTransaction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    event.preventDefault();

    const newState = [...transactions];

    newState.splice(index, 1);

    const { original } = transactions[index];
    const {
      date, description, id, type, value,
    } = original;

    const valueInCents: any = value * 100;
    TransactionsService.save({
      category: original.category || '',
      date,
      description,
      id: id || '',
      type,
      value: valueInCents,
    })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Register imported sucessfully',
          description:
            'Register imported sucessfully',
        });
      });

    onChange(newState);
  };

  const dismissTransaction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
  ) => {
    event.preventDefault();

    let newState = [...transactions];

    newState = newState.splice(index, 1);

    onChange(newState);
  };

  const handleCategoryChange = (event: any, index: number) => {
    const newState = [...transactions];
    newState[index].original.category = event.target.value;

    onChange(newState);
  };

  return (
    <div>
      {
        transactions.map((transaction: any, index: number) => (
          <ImportBox key={index}>
            <Description>{transaction.original.description}</Description>

            <Value>{ `R$ ${transaction.original.value}`}</Value>

            <Select
              name="category"
              label="Category"
              options={categories}
              onChange={(e) => handleCategoryChange(e, index)}
              showSelectOption
            />

            {
              transaction.found
              && (
                <div>
                  <span>Register already imported!</span>
                </div>
              )
            }

            <Controls>
              <Button onClick={(e) => importTransaction(e, index)}>Import</Button>
              <Button onClick={(e) => dismissTransaction(e, index)}>Dismiss</Button>
            </Controls>
          </ImportBox>
        ))
      }
    </div>
  );
};

export default ImporterTransactionList;
