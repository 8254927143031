import React, { ChangeEvent, useEffect, useState } from 'react';
import Joi from 'joi';
import { useHistory } from 'react-router-dom';

import Input from '../Input';
import RadioButtonList from '../RadioButtonList';
import Select from '../Select';
import TextArea from '../TextArea';

import { useToast } from '../../hooks/toast';

import { validate } from '../../utils/validation';
import InternalTemplate from '../../templates/Internal';
import TransactionsService, { ITransaction } from '../../services/transactions';
import CategoriesService, { ICategory } from '../../services/categories';

const TransactionForm: React.FC = (props: any) => {
  const [pageTitle, setPageTitle] = useState('Nova Transação');
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [formData, setFormData] = useState<ITransaction>({
    category: '',
    date: '',
    description: '',
    type: '',
    id: '',
    value: 0,
  });
  const [fieldErrors, setFieldErrors] = useState([]);
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    const { id } = props.match.params;

    CategoriesService.fetchAll()
      .then((response) => {
        setCategories(response);
      });

    if (!id) return;

    setPageTitle('Edit Transaction');

    TransactionsService.fetch(id)
      .then((transaction) => {
        setFormData(transaction);
      });
  }, [props]);

  const fieldsValidations = {
    date: Joi.string().required(),
    description: Joi.string().required(),
    value: Joi.number().required(),
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const errors = validate(fieldsValidations, formData);

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    TransactionsService.save(formData)
      .then(() => {
        history.push('/transactions');
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao salvar lançamento',
          description:
            'Ocorreu um erro ao salvar o lançamento, tente novamente.',
        });
      });
  };

  const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData((s) => ({ ...s, [event.target.name]: event.target.value }));
  };

  return (
    <InternalTemplate>
      <h1>{pageTitle}</h1>

      <form onSubmit={handleSubmit}>
        {
          fieldErrors && !!Object.entries(fieldErrors).length
          && (
            <div id="error_explanation">
              <h2>
                {Object.entries(fieldErrors).length}
                {' '}
                error(s) prohibited this transaction from being saved:
              </h2>
              <ul>
                {
                  Object.entries(fieldErrors).map((error, index) => (
                    <li>
                      <span>{Object.keys(fieldErrors)[index]}</span>
                      <span>-</span>
                      <span>{Object.values(fieldErrors)[index]}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }

        <TextArea
          label="Description"
          name="description"
          onChange={(event) => handleFieldChange(event)}
          value={formData.description}
        />

        <Input
          label="Date"
          name="date"
          value={formData.date}
          onChange={handleFieldChange}
        />

        <RadioButtonList
          options={[
            { value: 'expense', label: 'Expense' },
            { value: 'revenue', label: 'Revenue' },
          ]}
          name="type"
          onChange={handleFieldChange}
          defaultValue={formData.type}
        />

        <Input
          label="Value"
          name="value"
          value={formData.value}
          onChange={handleFieldChange}
        />

        <Select
          name="category"
          label="Category"
          showSelectOption
          options={categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))}
          onChange={handleFieldChange}
        />

        <div className="actions">
          <button className="btn btn-default" type="submit">Save</button>
        </div>
      </form>

    </InternalTemplate>
  );
};

export default TransactionForm;
