export default class DateService {
  static getApiSanitizedDate(date: string) {
    const re = new RegExp('(.{2})/(.{2})/(.{4})');

    const results = re.exec(date);

    if (!results) { return date; }

    const day = parseInt(results[1], 10);
    const month = parseInt(results[2], 10) - 1;
    const year = parseInt(results[3], 10);

    return new Date(year, month, day, 0, 0, 0, 0).toISOString();
  }
}
