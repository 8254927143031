import React, {
  createContext, useRef, useState,
} from 'react';

interface IAccordionContext {
  toggle?: any;
}

export const AccordionContext = createContext<IAccordionContext>({});

const Accordion: React.FC = (props) => {
  const [expanded, setExpandedState] = useState(false);
  const containerRef = useRef<any>({});

  const {
    children: childrenProp,
  } = props;

  const [summary, ...children] = React.Children.toArray(childrenProp);

  const handleChange = React.useCallback(
    (event) => {
      event.preventDefault();
      setExpandedState(!expanded);

      if (!expanded) containerRef.current.style.height = `${containerRef.current.scrollHeight}px`;
      else containerRef.current.style.height = '0';
    },
    [expanded],
  );

  const contextValue = React.useMemo(() => ({
    toggle: handleChange,
  }), [handleChange]);

  return (
    <div>
      <AccordionContext.Provider value={contextValue}>{summary}</AccordionContext.Provider>
      <div
        ref={containerRef}
        className="collapsing"
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
