import React from 'react';
import FormSignUp from '../../components/FormSignUp';
import BaseTemplate from '../../templates/Base';
import {
  Background, Container, Content, FormLink,
} from './styles';

const SignupPage: React.FC = () => (
  <BaseTemplate>
    <Container>
      <Content>
        <h2>Sign up</h2>

        <FormSignUp />

        <FormLink>
          <a href="/login">Log in</a>
        </FormLink>
      </Content>
      <Background />
    </Container>
  </BaseTemplate>
);

export default SignupPage;
