import { apiFetch } from './common';

export default class AuthService {
  static create(user: IUser) {
    return apiFetch('/auth/Register/', 'POST', user)
      .then((responseContent: any) => {
        const response: IUser = { ...responseContent };
        return response;
      });
  }
}

interface IUser {

}
