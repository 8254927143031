import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PeopleList: React.FC = () => {
  const people = [{
    id: 1,
    name: 'Rodrigo',
  }];

  return (
    <table className="table table-stripped">
      <tbody>
        {
          people.map((person) => (
            <tr key={person.id}>
              <td className="name-category">
                <a href="/people/{id}/edit">
                  {person.name}
                </a>
              </td>
              <td>
                <button className="btn btn-danger mr-3" type="button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default PeopleList;
