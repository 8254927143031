import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import TransactionForm from './components/TransactionForm';

import SettingsPage from './pages/Settings';
import PeopleFormPage from './pages/People/Form';
import CategoriesFormPage from './pages/Categories/Form';
import ReportsPage from './pages/Reports';
import ImporterPage from './pages/Importer';
import ExporterPage from './pages/Exporter';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';

import { AuthProvider } from './hooks/Auth';
import { ToastProvider } from './hooks/toast';
import HistoryPage from './pages/History';

ReactDOM.render(
  <Router>
    <AuthProvider>
      <ToastProvider>
        <Switch>
          <Route path="/sign_up" component={SignupPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/exporter" component={ExporterPage} />
          <Route path="/importer" component={ImporterPage} />
          <Route path="/reports/:startDate/:endDate" component={ReportsPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route path="/categories/:id/edit" component={CategoriesFormPage} />
          <Route path="/categories/new" component={CategoriesFormPage} />
          <Route path="/people/new" component={PeopleFormPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/transactions/:id/edit" component={TransactionForm} />
          <Route path="/transactions/new" component={TransactionForm} />
          <Route path="/transactions/:startDate/:endDate" component={HistoryPage} />
          <Route path="/transactions" component={HistoryPage} />
          <Route exact path="/" component={LoginPage} />
        </Switch>
      </ToastProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root'),
);
