import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImporterTransactionList, { IConcilatedTransaction } from '../../components/ImporterTransactionList';
import { OptionProps } from '../../components/Select';
import { useToast } from '../../hooks/toast';
import CategoriesService, { ICategory } from '../../services/categories';
import DataService from '../../services/data';
import InternalTemplate from '../../templates/Internal';

const Importer: React.FC = () => {
  const [categories, setCategories] = useState<OptionProps[]>([]);
  const [transactions, setTransactions] = useState<IConcilatedTransaction[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    CategoriesService.fetchAll()
      .then((response: ICategory[]) => {
        setCategories(response.map((category) => ({
          label: category.name,
          value: category.id,
        })));
      });
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const data = new FormData();

    data.append('file', acceptedFiles[0]);

    DataService.conciliateImport(data)
      .then((response) => {
        setTransactions(response.data.map((transaction: IConcilatedTransaction) => {
          let { value } = transaction.original;
          if (value < 0) value *= -1;

          value /= 100;

          transaction.original.value = value;

          return transaction;
        }));

        addToast({
          type: 'success',
          title: 'File imported successfully',
          description:
            'File imported successfully!',
        });
      })
      .catch((err) => {
        if (err === 'Bad Request') {
          addToast({
            type: 'error',
            title: 'Invalid file provided',
            description:
              'Invalid file provided',
          });
        }
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleTransactionUpdate = (state: IConcilatedTransaction[]) => {
    setTransactions(state);
  };

  return (
    <InternalTemplate>
      <h1>Importer</h1>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive
            ? <p>Drop the files here ...</p>
            : <p>Drag n drop some files here, or click to select files</p>
        }
      </div>

      <ImporterTransactionList
        transactions={transactions}
        categories={categories}
        onChange={handleTransactionUpdate}
      />
    </InternalTemplate>
  );
};

export default Importer;
