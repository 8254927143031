import React from 'react';
import CategoryList from '../../components/CategoryList';
import { HalfColumn } from '../../components/Columns';
import LinkButton from '../../components/LinkButton';
import PeopleList from '../../components/PeopleList';
import { Row } from '../../components/Row';
import InternalTemplate from '../../templates/Internal';
import { Card } from './styles';

const SettingsPage: React.FC = () => (
  <InternalTemplate>
    <div>
      <h1>Cadastros</h1>

      <Row>
        <HalfColumn>
          <Card>
            <h2>People</h2>

            <PeopleList />
            <LinkButton href="/people/new">New person</LinkButton>
          </Card>
        </HalfColumn>

        <HalfColumn>
          <Card>
            <h2>Categories</h2>
            <CategoryList />
            <LinkButton href="/categories/new">New category</LinkButton>
          </Card>
        </HalfColumn>
      </Row>

      {/* <%= link_to delete_all_transaction_path, method: :delete,
          data: { confirm: 'Esta ação é irreversível. Deseja continuar?' } do %>
          <span>Excluir todas as transações</span>
        <% end %>  */}

    </div>
  </InternalTemplate>
);

export default SettingsPage;
