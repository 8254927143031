import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../Button';
import Input from '../Input';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/toast';
import { ForgotPasswordLink } from './styles';

interface IFormData {
  email: string;
  password: string;
}

const FormLogin: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    password: '',
  });

  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useHistory();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    try {
      signIn({
        email: formData.email,
        password: formData.password,
      })
        .then(() => {
          history.push('/transactions');
        })
        .catch((err) => {
          addToast({
            type: 'error',
            title: 'Erro ao realizar login',
            description:
            err,
          });
        });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao realizar login',
        description:
          'Ocorreu um erro ao realizar o login, tente novamente.',
      });
    }
  };

  const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData((s) => ({ ...s, [event.target.name]: event.target.value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Email"
        name="email"
        placeholder="Email"
        onChange={handleFieldChange}
        value={formData.email}
      />

      <Input
        label="Password"
        name="password"
        type="password"
        placeholder="Password"
        onChange={handleFieldChange}
        value={formData.password}
      />

      <ForgotPasswordLink to="/users/password/new">
        Forgot your password?
      </ForgotPasswordLink>

      <Button>Login</Button>
    </form>
  );
};

export default FormLogin;
