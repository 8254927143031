import React from 'react';
import { IReportSummaryProps } from '../../interfaces/IReportSummaryProps';
import Accordion from '../Accordion';
import AccordionDetails from '../Accordion/AccordionDetails';
import AccordionSummary from '../Accordion/AccordionSummary';
import { AccordionHead, Table } from './styles';

const ReportSummary: React.FC<IReportSummaryProps> = ({ reportRows, reportTotal, type }) => (
  <div>
    <h1>{type}</h1>
    {
      reportRows.map((reportRow, index) => (
        <Accordion key={index}>
          <AccordionSummary>
            <AccordionHead>
              <span>{reportRow.report.name}</span>
              <span>{reportRow.report.total / 100.0}</span>
            </AccordionHead>
          </AccordionSummary>

          <AccordionDetails>
            <Table className="table stripped-table">
              <tbody>
                {
                  reportRow.report.transactions.map((transaction) => (
                    <tr key={transaction.description}>
                      <td className="category-column">
                        <a href={transaction.description}>
                          {transaction.description}
                        </a>
                      </td>
                      <td className="percentage-column" />
                      <td className="value-column">{transaction.value / 100.0}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot>
                <tr>
                  <td className="category-column">Total:</td>
                  <td className="percentage-column">100%</td>
                  <td className="value-column">{reportRow.report.total / 100.0}</td>
                </tr>
              </tfoot>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))
    }
    <table className="table">
      <tbody>
        <tr>
          <td className="category-column">Total:</td>
          <td className="percentage-column" />
          <td className="value-column">{reportTotal / 100.0}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default ReportSummary;
