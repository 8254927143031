import React from 'react';
import { AccordionContext } from '..';
import { Container } from './styles';

export interface IAccordionSummaryProps {
  children: any;
}

const AccordionSummary: React.FC<IAccordionSummaryProps> = ({ children }): any => {
  const { toggle } = React.useContext(AccordionContext);

  const handleClick = (event: any) => {
    if (toggle) {
      toggle(event);
    }
  };

  return (
    <Container>
      <button type="button" onClick={handleClick}>
        {children}
      </button>
    </Container>
  );
};

export default AccordionSummary;
