import {
  faCheckCircle, faExclamationCircle, faInfo, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

import { useToast } from '../../../hooks/toast';
import { ToastMessage } from '../../../interfaces/IToastMessage';

import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FontAwesomeIcon icon={faInfo} size="1x" />,
  error: <FontAwesomeIcon icon={faExclamationCircle} size="1x" />,
  success: <FontAwesomeIcon icon={faCheckCircle} size="1x" />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    if (!message.autoHide) return () => {};

    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  const handleConfirmClick = () => {
    removeToast(message.id);

    if (message.confirmCallback) message.confirmCallback();
  };

  return (
    <Container
      type={message.type}
      hasdescription={Number(!!message.description)}
      style={style}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && (
          <p>{message.description}</p>
        )}

        <div>
          {message.confirmCallback
            && <button type="button" onClick={handleConfirmClick}>Confirm</button>}
        </div>
      </div>

      <button type="button" onClick={() => removeToast(message.id)} className="close">
        <FontAwesomeIcon icon={faTimes} size="sm" />
      </button>
    </Container>
  );
};

export default Toast;
