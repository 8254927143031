import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  InputHTMLAttributes, useCallback, useRef, useState,
} from 'react';
import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}

const Input: React.FC<InputProps> = ({
  label, name, error, ...attributes
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <div className="form-group">
      <Container
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <label htmlFor={name}>{label}</label>
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          name={name}
          {...attributes}
        />

        {error && (
          <Error title={error}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </Error>
        )}

      </Container>
    </div>
  );
};

export default Input;
