import React from 'react';
import { ICategory } from '../../services/categories';
import { ITransaction } from '../../services/transactions';

import {
} from '../../utils/date';
import TransactionListItem from '../TransactionListItem';
import { Container } from './styles';

interface ITransactionListProps {
  categories: ICategory[];
  transactions: ITransaction[];
  onTransactionDelete(): void;
}

const TransactionList: React.FC<ITransactionListProps> = ({
  categories,
  transactions,
  onTransactionDelete,
}) => {
  const handleTransactionDelete = () => {
    onTransactionDelete();
  };

  return (
    <Container>
      {
        transactions && transactions.map((transaction) => (
          <TransactionListItem
            key={transaction.id}
            categories={categories}
            transaction={transaction}
            onDelete={handleTransactionDelete}
          />
        ))
      }
    </Container>
  );
};

export default TransactionList;
