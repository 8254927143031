import Joi from 'joi';

export type FieldError = {
  [key: string]: string
};

function getFieldErrors(objError: any) {
  const errors: any = {};

  if (objError.error) {
    objError.error.details.forEach((err: any) => {
      let message = '';
      switch (err.type) {
        case 'any.empty':
        case 'string.empty':
        case 'any.required':
          message = 'Required field!';
          break;
        default:
          message = err.message;
          break;
      }
      errors[err.path.join('.')] = message;
    });
  }

  return errors;
}

export const validate = (fieldsValidations: any, formData: any) => {
  const schema = Joi.object(fieldsValidations);

  return getFieldErrors(schema.validate(formData, {
    abortEarly: false,
    allowUnknown: true,
  }));
};
