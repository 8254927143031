import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({
    theme, isErrored, isFocused, isFilled,
  }) => css`
    position: relative;
    svg {
      margin-right: 16px;
    }

    input {
      align-items: center;
      background-color: #232129;
      border: 1px solid ${theme.colors.primary[200]};
      border-radius: 10px;
      color: #f4ede8;
      display: flex;
      padding: 16px;
      transition: border-color 0.3s;
      width: 100%;

      ${isErrored
        && css`
          border-color: #c53030;
        `}

      ${isFocused
        && css`
          border-color: ${theme.colors.primary[100]};
        `}

      ${isFilled
        && css`
          color: #ff9000;
        `}


      &::placeholder {
        color: #666360;
      }
    }
  `}
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  position: absolute;
  right: 20px;
  top: calc(50% + 3px);

  svg {
    color: #c53030;
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
