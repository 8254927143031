import { apiFetch } from './common';

export default class ReportsService {
  static fetch(startDate: string, endDate: string) {
    return apiFetch(`/report/${startDate}/${endDate}`, 'GET')
      .then((responseContent) => {
        const response: IReport = { ...responseContent };
        return response;
      });
  }
}

export interface IReport {
  expenseRows: Array<Array<string>>;
  expenseTotal: number;
  revenueRows: Array<Array<string>>;
  revenueTotal: number;
  grouppedExpenseTransactions: IReportItem;
  grouppedRevenueTransactions: IReportItem;
}

export interface IReportItem {
  [key: string]: {
    name: string;
    transactions: [{
      id: string;
      value: number;
      description: string;
      userId: string;
      date: string;
      type: string;
      category: string;
    }],
    percentage: number;
    total: number;
  }
}
