import React, { TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, name, ...attributes }) => (
  <div className="form-group">
    <label htmlFor={name}>
      {label}
    </label>
    <textarea className="form-control" name={name} {...attributes} />
  </div>
);

export default TextArea;
