import api from './api';
import { apiFetch } from './common';

export default class DataService {
  static export() {
    const token = localStorage.getItem('@Investify:token');
    api.defaults.headers.authorization = `Bearer ${token}`;

    return apiFetch('/export/', 'GET')
      .then((responseContent: any) => {
        const response: any = { ...responseContent };
        return response;
      });
  }

  static conciliateImport(data: FormData) {
    const token = localStorage.getItem('@Investify:token');
    api.defaults.headers.authorization = `Bearer ${token}`;

    return api.post('/importer/conciliate', data)
      .then((responseContent: any) => {
        const response: any = { ...responseContent };
        return response;
      });
  }
}
