import React, { useEffect, useState } from 'react';
import CategoriesService, { ICategory } from '../../services/categories';
import CategoryListItem from './CategoryListItem';

const CategoryList: React.FC = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);

  const fetchCategories = () => {
    CategoriesService.fetchAll()
      .then((response) => {
        setCategories(response);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleDelete = () => {
    fetchCategories();
  };

  return (
    <table className="table table-stripped">
      <tbody>
        {
          categories.map((category) => (
            <CategoryListItem key={category.id} category={category} onDelete={handleDelete} />
          ))
        }
      </tbody>
    </table>
  );
};

export default CategoryList;
