import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { HalfColumn } from '../../components/Columns';

import ReportSummary from '../../components/ReportSummary';
import { Row } from '../../components/Row';
import { IReportSummaryReportRow } from '../../interfaces/IReportSummaryProps';
import ReportsService, { IReport } from '../../services/report';
import InternalTemplate from '../../templates/Internal';

import {
  dateToInterval,
  getMonthName,
  getNextInterval,
  getPreviousInterval,
  setLastDayOfMonth,
} from '../../utils/date';
import { Controls } from './styles';

const ReportsPage: React.FC = (props: any) => {
  const [expenseRows, setExpenseRows] = useState<Array<Array<string>>>([]);
  const [revenueRows, setRevenueRows] = useState<Array<Array<string>>>([]);
  const [revenueTotal, setRevenueTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [revenueReportRows, setRevenueReportRows] = useState<IReportSummaryReportRow[]>([]);
  const [expenseReportRows, setExpenseReportRows] = useState<IReportSummaryReportRow[]>([]);
  const [startDateState, setStartDate] = useState('');
  const [endDateState, setEndDate] = useState('');
  const [interval, setInterval] = useState('');

  const fetchReport = (startDate = '', endDate = '') => {
    ReportsService.fetch(startDate, endDate)
      .then((response: IReport) => {
        setRevenueTotal(response.revenueTotal);
        setExpenseTotal(response.expenseTotal);
        setExpenseRows(response.expenseRows);
        setRevenueRows(response.revenueRows);

        const groupedExpenseRowsState = response.grouppedExpenseTransactions && Object
          .values(response.grouppedExpenseTransactions)
          .map((item) => ({
            category: 'expense',
            report: {
              name: item.name,
              percentage: item.percentage,
              total: item.total,
              transactions: item.transactions,
            },
          }));

        setExpenseReportRows(groupedExpenseRowsState);

        const groupedRevenueRowsState = response.grouppedRevenueTransactions && Object
          .values(response.grouppedRevenueTransactions)
          .map((item) => ({
            category: 'revenue',
            report: {
              name: item.name,
              percentage: item.percentage,
              total: item.total,
              transactions: item.transactions,
            },
          }));

        setRevenueReportRows(groupedRevenueRowsState);
      });
  };

  useEffect(() => {
    let { startDate, endDate } = props.match.params;

    if (!startDate) {
      const auxDate = new Date();
      auxDate.setDate(1);
      startDate = dateToInterval(auxDate);
    }

    if (!endDate) {
      endDate = setLastDayOfMonth(new Date());
      endDate = dateToInterval(endDate);
    }

    setStartDate(startDate);
    setEndDate(endDate);

    const yearInterval = startDate.split('-')[0];
    const monthInterval = endDate.split('-')[1];

    setInterval(`${getMonthName(monthInterval)} ${yearInterval}`);

    fetchReport(startDate, endDate);
  }, []);

  return (
    <InternalTemplate>
      <Controls>
        <div className="filters-container">
          <a href={`/reports/${getPreviousInterval(startDateState, false)}/${getPreviousInterval(endDateState, true)}`}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </a>
          <a href={`/reports/${startDateState}/${endDateState}`}>{interval}</a>
          <a href={`/reports/${getNextInterval(startDateState, false)}/${getNextInterval(endDateState, true)}`}>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </div>
      </Controls>

      <div className="container">
        <Row className="gutter-0 row">
          <HalfColumn className="chart-container">
            <Chart
              chartType="PieChart"
              data={[['Value', 'Value'], ...expenseRows]}
              width="100%"
              height="400px"
              options={
                {
                  backgroundColor: '#111',
                  is3D: true,
                  legendTextStyle: { color: '#FFF' },
                }
              }
              legendToggle
            />
          </HalfColumn>
          <HalfColumn className="chart-container">
            <Chart
              chartType="PieChart"
              data={[['Value', 'Value'], ...revenueRows]}
              width="100%"
              height="400px"
              options={
                {
                  backgroundColor: '#111',
                  is3D: true,
                  legendTextStyle: { color: '#FFF' },
                }
              }
              legendToggle
            />
          </HalfColumn>
        </Row>

        {
          !!revenueTotal
          && !!revenueReportRows
          && (
            <ReportSummary type="Revenue" reportTotal={revenueTotal} reportRows={revenueReportRows} />
          )
        }

        {
          !!expenseTotal
          && !!expenseReportRows
          && (
            <ReportSummary type="Expenses" reportTotal={expenseTotal} reportRows={expenseReportRows} />
          )
        }
      </div>

    </InternalTemplate>
  );
};

export default ReportsPage;
