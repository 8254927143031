import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const ForgotPasswordLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[200]};
    font-size: ${theme.font.sizes.xsmall};
    text-decoration: none;
  `}
`;
