export const getMonthName = (idx: number) => {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx - 1);

  const locale = window.navigator.language.toLowerCase(); // 'en-us';
  const month = objDate.toLocaleString(locale, { month: 'long' });

  return month;
};

export const increaseMonth = (date: Date, months: number) => {
  const auxDate = new Date(date);
  const currentMonth = date.getMonth();

  date.setMonth(currentMonth + months);

  while (auxDate.getMonth() + months < date.getMonth()) date.setDate(date.getDate() - 1);

  return date;
};

export const isAlreadyEndOfMonth = (date: Date) => {
  const auxDate = new Date(date);
  auxDate.setDate(auxDate.getDate() + 1);

  return (auxDate.getMonth() > date.getMonth());
};

export const setLastDayOfMonth = (date: Date) => {
  if (isAlreadyEndOfMonth(date)) return date;

  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  return date;
};

const sanitizeDatePart = (datePart: number) => (datePart < 10 ? `0${datePart}` : datePart);

export const dateToInterval = (date: Date) => `${date.getFullYear()}-${sanitizeDatePart(date.getMonth() + 1)}-${sanitizeDatePart(date.getDate())}`;

export const intervalToDate = (givenInterval: string) => {
  const dateSplited = givenInterval.split('-');

  return new Date(
    parseInt(dateSplited[0], 10),
    parseInt(dateSplited[1], 10) - 1,
    parseInt(dateSplited[2], 10), 0, 0, 0,
  );
};

export const getPreviousInterval = (givenInterval: string, isSetLastDayOfMonth: boolean) => {
  let date = intervalToDate(givenInterval);

  const oldMonth = new Date(date);
  date.setMonth(date.getMonth() - 1);

  while (oldMonth.getMonth() === date.getMonth()) {
    date.setDate(date.getDate() - 1);
  }

  if (isSetLastDayOfMonth) date = setLastDayOfMonth(date);

  return dateToInterval(date);
};

export const getNextInterval = (givenInterval: string, isSetLastDayOfMonth: boolean) => {
  let date = intervalToDate(givenInterval);

  date = increaseMonth(date, 1);

  if (isSetLastDayOfMonth) date = setLastDayOfMonth(date);

  return dateToInterval(date);
};
