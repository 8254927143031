import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  getNextInterval,
  getPreviousInterval,
} from '../../utils/date';
import { SelectorLink } from './styles';

interface IDateIntervalControlProps {
  startDateState: string;
  endDateState: string;
  interval: string;
}

const DateIntervalControl: React.FC<IDateIntervalControlProps> = ({
  startDateState,
  endDateState,
  interval,
}) => (
  <div className="filters-container">
    <SelectorLink href={`/transactions/${getPreviousInterval(startDateState, false)}/${getPreviousInterval(endDateState, true)}`}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </SelectorLink>
    <SelectorLink href={`/transactions/${startDateState}/${endDateState}`}>{interval}</SelectorLink>
    <SelectorLink href={`/transactions/${getNextInterval(startDateState, false)}/${getNextInterval(endDateState, true)}`}>
      <FontAwesomeIcon icon={faChevronRight} />
    </SelectorLink>
  </div>
);

export default DateIntervalControl;
