import React, { useEffect, useState } from 'react';
import DateIntervalControl from '../../components/DateIntervalControl';
import TransactionList from '../../components/TransactionList';
import CategoriesService, { ICategory } from '../../services/categories';
import TransactionsService, { ITransaction } from '../../services/transactions';
import InternalTemplate from '../../templates/Internal';
import { dateToInterval, getMonthName, setLastDayOfMonth } from '../../utils/date';

const HistoryPage: React.FC = (props: any) => {
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [startDateState, setStartDate] = useState('');
  const [endDateState, setEndDate] = useState('');
  const [interval, setInterval] = useState('');

  const fetchTransactions = (startDate = '', endDate = '') => {
    TransactionsService.fetchAll(startDate, endDate)
      .then((response) => {
        setTransactions(response);
      });
  };

  const fetchCategories = () => {
    CategoriesService.fetchAll()
      .then((response) => {
        setCategories(response);
      });
  };

  useEffect(() => {
    let { startDate, endDate } = props.match.params;

    if (!startDate) {
      const auxDate = new Date();
      auxDate.setDate(1);
      startDate = dateToInterval(auxDate);
    }

    if (!endDate) {
      endDate = setLastDayOfMonth(new Date());
      endDate = dateToInterval(endDate);
    }

    setStartDate(startDate);
    setEndDate(endDate);

    const yearInterval = startDate.split('-')[0];
    const monthInterval = endDate.split('-')[1];

    setInterval(`${getMonthName(monthInterval)} ${yearInterval}`);

    fetchCategories();
    fetchTransactions(startDate, endDate);
  }, []);

  const handleTransactionDelete = () => {
    fetchTransactions(startDateState, endDateState);
  };

  return (
    <InternalTemplate>
      <DateIntervalControl
        startDateState={startDateState}
        endDateState={endDateState}
        interval={interval}
      />

      <TransactionList
        categories={categories}
        transactions={transactions}
        onTransactionDelete={handleTransactionDelete}
      />

      <a href="/transactions/new">Nova transação</a>
    </InternalTemplate>
  );
};

export default HistoryPage;
